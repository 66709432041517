import React from 'react';
import SEO from '../../../components/SEO';
import SectionLayout from '../../../layouts/SectionLayout';
import calloutImg from '../../../images/cai_imagery_transparent.png';

export default function SoftwareImagery() {
  return (
    <>
      <SEO />
      <SectionLayout>
        <p className="section-display-subheader">
          VISUAL IMAGERY INTO VALUABLE INSIGHTS
        </p>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              {`Over the past few years, drones and satellites have made it easy
              to capture detailed imagery, illuminating vegetation health not
              visible to the naked eye. We've created a cloud-based platform to
              process, store and present this valuable imagery with dynamic,
              online map views.`}
            </p>
          </div>
        </div>
        <div className="row mobile-desktop-img-margin">
          <div
            className="col-sm-12 text-center"
            style={{
              maxWidth: '1000px',
              margin: '0 auto',
            }}
          >
            <img
              alt="Imagery illustration"
              className="img-fluid"
              src={calloutImg}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
